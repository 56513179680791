import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { deleteFromBasket } from "../Components/Basket";
import LoadingOverlay from "../Components/LoadingOverlay";
import Popup from "../Components/Popup";

import { url } from "../Hooks/FetchRequest";

import {
    calculatePrice,
    calculatePriceExclVat,
    calculateVAT,
    calculateTotal,
} from "../Functions/Prices";

import CrossIcon from "../assets/img/plus-circle.svg";
import PlusIcon from "../assets/img/plus-circle-accent-color.svg";

function Checkout() {
    const { t } = useTranslation(["shop", "common"]);
    const [items, setItems] = useState();
    const [Loading, setLoading] = useState(false);
    const [shipmentCost, changeShipmentCost] = useState(
        parseInt(sessionStorage.getItem("shipmentcost")) || 7.0
    );
    const [popup, setPopup] = useState({ status: false, description: "" });
    const [country, setCountry] = useState(
        sessionStorage.getItem("country")
            ? sessionStorage.getItem("country")
            : "Belgium"
    );

    useEffect(() => {
        fetchDetails(JSON.parse(localStorage.getItem("basket")));
    }, []);

    useEffect(() => {
        changeShipmentCost(calculateShipmentCost(country, items));
        localStorage.setItem("basket", JSON.stringify(items));
    }, [items, country]);

    let content = <h2>{t("common:loading")}</h2>;

    if (items) {
        content = (
            <main className="checkout">
                <LoadingOverlay visible={Loading} />
                <Popup
                    title={"Heads up!"}
                    visible={popup.status}
                    description={popup.description}
                    setPopup={setPopup}
                />
                <section>
                    <UserInfo
                        items={items}
                        setLoading={setLoading}
                        changeShipmentCost={changeShipmentCost}
                        setPopup={setPopup}
                        setCountry={setCountry}
                    />
                </section>
                <section>
                    <h2>{t("basket")}</h2>

                    <ul>
                        {items.map((item, index) => {
                            return (
                                <BasketItem
                                    key={index}
                                    item={item}
                                    index={index}
                                    itemCollection={items}
                                    quantity={item.quantity}
                                    setItems={setItems}
                                />
                            );
                        })}
                    </ul>
                    <Link to="/shop" className="plus-icon">
                        <img src={PlusIcon} alt="Add another item" />
                    </Link>

                    <DetailWindow items={items} shipmentCost={shipmentCost} />
                </section>
            </main>
        );
    }

    async function fetchDetails(ProductsArray) {
        let ProductDetailArray = [];
        if (ProductsArray) {
            for (const product of ProductsArray) {
                let productDetails = await fetch(
                    url + `/shop/products/${product.id}`
                );
                let results = await productDetails.json();

                results.quantity = product.quantity;
                if (results.price) {
                    ProductDetailArray.push(results);
                }
            }
        }
        setItems(ProductDetailArray);
    }

    return content;
}

function BasketItem(props) {
    const { item, index, itemCollection, setItems, quantity } = props;
    const { t, i18n } = useTranslation(["shop", "common"]);

    return (
        <li>
            <img
                src={url + item.images[0].image}
                alt={
                    item.titles.filter(
                        (title) => title.lang === i18n.languages[0]
                    )[0].title
                }
            />
            <p>
                {
                    item.titles.filter(
                        (title) => title.lang === i18n.languages[0]
                    )[0].title
                }
            </p>
            <p className="quantity-lg">{t("quantity.big")}</p>
            <p className="quantity-sm">{t("quantity.small")}</p>
            <input
                type="nubmer"
                min="1"
                step="1"
                onChange={(e) => changeQuantity(e, index)}
                defaultValue={quantity}
            />

            <p className="details">
                {t("vat")}: {item.vat}%
            </p>
            <p className="details">€ {calculatePrice(item)}</p>
            <img
                src={CrossIcon}
                onClick={() => deleteItem(index)}
                alt="Delete item"
            />
        </li>
    );

    function deleteItem(itemIndex) {
        deleteFromBasket(itemIndex);
        let tmp = itemCollection;
        setItems(tmp.filter((filterItem, i) => i !== itemIndex));
    }

    function changeQuantity(e, itemIndex) {
        let tmp = JSON.parse(JSON.stringify(itemCollection));
        tmp[itemIndex].quantity = e.target.value === "" ? 0 : e.target.value;
        setItems(tmp);
    }
}

function UserInfo({
    items,
    setLoading,
    changeShipmentCost,
    setPopup,
    setCountry,
}) {
    const { t } = useTranslation(["shop", "common"]);

    let content = (
        <form
            id="information-form"
            onSubmit={(form) => continueToCheckout(form)}
        >
            <h2>{t("customer.title")}</h2>
            <div className="flex-row">
                <div className="input-container">
                    <label htmlFor="first_name">
                        {t("customer.firstName")}
                    </label>
                    <input
                        name="first_name"
                        id="first_name"
                        required
                        autoFocus
                        onChange={(e) =>
                            sessionStorage.setItem("first_name", e.target.value)
                        }
                        defaultValue={
                            sessionStorage.getItem("first_name")
                                ? sessionStorage.getItem("first_name")
                                : ""
                        }
                    />
                </div>

                <div className="input-container">
                    <label htmlFor="surname">{t("customer.lastName")}</label>
                    <input
                        name="family_name"
                        id="surname"
                        required
                        onChange={(e) =>
                            sessionStorage.setItem("surname", e.target.value)
                        }
                        defaultValue={
                            sessionStorage.getItem("surname")
                                ? sessionStorage.getItem("surname")
                                : ""
                        }
                    />
                </div>
            </div>

            <div className="input-container">
                <label htmlFor="email">{t("customer.email")}</label>
                <input
                    name="email"
                    id="email"
                    type="email"
                    required
                    onChange={(e) =>
                        sessionStorage.setItem("email", e.target.value)
                    }
                    defaultValue={
                        sessionStorage.getItem("email")
                            ? sessionStorage.getItem("email")
                            : ""
                    }
                />
            </div>

            <div className="input-container">
                <label htmlFor="country">{t("customer.country")}</label>
                <CountrySelector
                    changeShipmentCost={changeShipmentCost}
                    setPopup={setPopup}
                    items={items}
                    setCountry={setCountry}
                />
            </div>

            <div className="flex-row">
                <div className="input-container expanded-small">
                    <label htmlFor="Address">{t("customer.address")}</label>
                    <input
                        name="address"
                        id="Address"
                        required
                        onChange={(e) =>
                            sessionStorage.setItem("address", e.target.value)
                        }
                        defaultValue={
                            sessionStorage.getItem("address")
                                ? sessionStorage.getItem("address")
                                : ""
                        }
                    />
                </div>

                <div className="input-container">
                    <label htmlFor="Addition">{t("customer.addition")}</label>
                    <input
                        name="Addition"
                        id="Addition"
                        placeholder="Apartment, suite, etc. (optional)"
                        onChange={(e) =>
                            sessionStorage.setItem("addition", e.target.value)
                        }
                        defaultValue={
                            sessionStorage.getItem("addition")
                                ? sessionStorage.getItem("addition")
                                : ""
                        }
                    />
                </div>
            </div>

            <div className="flex-row">
                <div className="input-container expanded">
                    <label htmlFor="City">{t("customer.city")}</label>
                    <input
                        name="city"
                        id="City"
                        required
                        onChange={(e) =>
                            sessionStorage.setItem("city", e.target.value)
                        }
                        defaultValue={
                            sessionStorage.getItem("city")
                                ? sessionStorage.getItem("city")
                                : ""
                        }
                    />
                </div>

                <div className="input-container">
                    <label htmlFor="postal_code">
                        {t("customer.postalCode")}
                    </label>
                    <input
                        name="postal_code"
                        id="postal_code"
                        required
                        onChange={(e) =>
                            sessionStorage.setItem(
                                "postal_code",
                                e.target.value
                            )
                        }
                        defaultValue={
                            sessionStorage.getItem("postal_code")
                                ? sessionStorage.getItem("postal_code")
                                : ""
                        }
                    />
                </div>
            </div>

            <div className="input-container">
                <label htmlFor="Phone">{t("customer.phone")}</label>
                <input
                    name="phone"
                    id="Phone"
                    type="phone"
                    required
                    onChange={(e) =>
                        sessionStorage.setItem("phone", e.target.value)
                    }
                    defaultValue={
                        sessionStorage.getItem("phone")
                            ? sessionStorage.getItem("phone")
                            : ""
                    }
                />
            </div>
            <div className="input-container">
                <label htmlFor="message">{t("customer.message")}</label>
                <textarea
                    name="message"
                    id="message"
                    type="phone"
                    onChange={(e) =>
                        sessionStorage.setItem("message", e.target.value)
                    }
                    defaultValue={
                        sessionStorage.getItem("message")
                            ? sessionStorage.getItem("message")
                            : ""
                    }
                ></textarea>
            </div>
        </form>
    );

    function continueToCheckout(e) {
        e.preventDefault();
        setLoading(true);

        let formDataJSON = {};
        let formData = new FormData(e.currentTarget);
        formData.forEach((value, key) => {
            formDataJSON[key] = value;
        });

        let finalData = {
            customer: { ...formDataJSON },
            products: items,
        };

        let options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(finalData),
        };

        fetch(`${url}/shop/order`, options).then(async (r) => {
            if (r.ok) {
                let link = await r.json();
                window.location.href = link.link;
            }
        });
    }

    return content;
}

function DetailWindow(props) {
    const { items, shipmentCost } = props;
    const { t } = useTranslation(["shop", "common"]);
    const [TOC, setTOC] = useState(false);

    const content = (
        <div className="details">
            <h2>{t("shipping.title")}</h2>
            <div>
                <table>
                    <tbody>
                        <tr>
                            <td>{t("shipping.price")}</td>
                            <td>€{calculatePriceExclVat(items)}</td>
                        </tr>
                        <tr>
                            <td>{t("shipping.vat")}</td>
                            <td>€{calculateVAT(items)}</td>
                        </tr>
                        <tr>
                            <td>{t("shipping.shipping")}</td>
                            <td>€{shipmentCost.toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td>{t("shipping.total")}</td>
                            <td>
                                €
                                {parseFloat(
                                    calculateTotal(items, shipmentCost)
                                ).toFixed(2)}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <input
                    type="checkbox"
                    id="toc"
                    onChange={(e) => setTOC(e.target.checked)}
                />
                <label htmlFor="toc">
                    {t("shipping.agree")}{" "}
                    <Link to="/toc">{t("shipping.toc")}</Link>
                </label>

                <input
                    type="submit"
                    form="information-form"
                    value="Continue to payment"
                    disabled={!TOC}
                />
            </div>
        </div>
    );

    return content;
}

function CountrySelector({ changeShipmentCost, setPopup, items, setCountry }) {
    const { t } = useTranslation(["shop", "common"]);

    return (
        <select
            name="country"
            id="country"
            defaultValue={
                sessionStorage.getItem("country")
                    ? sessionStorage.getItem("country")
                    : "Belgium"
            }
            onChange={(e) => handleChange(e)}
        >
            <option value="Austria">Austria</option>
            <option value="Belgium">Belgium</option>
            <option value="Bulgaria">Bulgaria</option>
            <option value="Croatia">Croatia</option>
            <option value="Cyprus">Cyprus</option>
            <option value="Czechia">Czechia</option>
            <option value="Denmark">Denmark</option>
            <option value="Estonia">Estonia</option>
            <option value="Finland">Finland</option>
            <option value="France">France</option>
            <option value="Germany">Germany</option>
            <option value="Greece">Greece</option>
            <option value="Hungary">Hungary</option>
            <option value="Ireland">Ireland</option>
            <option value="Italy">Italy</option>
            <option value="Latvia">Latvia</option>
            <option value="Lithuania">Lithuania</option>
            <option value="Luxembourg">Luxembourg</option>
            <option value="Malta">Malta</option>
            <option value="Netherlands">Netherlands</option>
            <option value="Poland">Poland</option>
            <option value="Portugal">Portugal</option>
            <option value="Romania">Romania</option>
            <option value="Slovakia">Slovakia</option>
            <option value="Slovenia">Slovenia</option>
            <option value="Spain">Spain</option>
            <option value="Sweden">Sweden</option>
            <option value="United Kingdom">United Kingdom</option>
        </select>
    );

    function handleChange(e) {
        selectCountry(e.target.value, items, setCountry);
        sessionStorage.setItem("country", e.target.value);
        if (e.target.value === "Spain" || e.target.value === "Italy" || e.target.value === "Greece" || e.target.value === "Austria") {
            setPopup({
                status: true,
                description: t("shipping.warningWeight"),
            });
        } else if (e.target.value === "United Kingdom") {
            setPopup({
                status: true,
                description: t("shipping.warningUK"),
            });
        }
    }

    function selectCountry(country, basketItems, setCountry) {
        let cost = calculateShipmentCost(country, basketItems);
        changeShipmentCost(cost);
        setCountry(country);
        sessionStorage.setItem("shipmentcost", cost);
    }
}

function calculateShipmentCost(country, basketItems) {
    let cost = 0;
    let weight = basketItems
        ? basketItems.reduce(
              (totalWeight, item) =>
                  totalWeight + item.weight * parseInt(item.quantity),
              0
          )
        : 0;
    if (country === "Belgium") {
        cost = 7.0;
    } else if (country === "United Kingdom") {
        cost = weight >= 10 ? 80.0 : weight >= 5 ? 74.0 : 35.0;
    } else if (country === "Spain") {
        cost = weight >= 5 ? 53.0 : 35.0;
    } else if (country === "Italy" || country === "Greece" || country === "Austria") {
        cost = weight >= 2 && weight <= 5 ? 35 : 17.0;
    } else {
        cost = weight >= 5 ? 32.8 : 17.0;
    }

    return cost;
}

export default Checkout;
